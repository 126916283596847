import { Env } from '@env/model';
import packageJson from 'package.json';

export const environment: Env = {
  production: true,
  disableLogs: true,
  show18nSwitch: false,
  version: 'rc',
  versionFull: `${packageJson.version}-rc`,
  urls: {
    OLD_MAIN_API: 'https://www.rc.radioking.com/api',
    MAIN_API_V2: 'https://api.rc.radioking.io',
    WIDGET_API: 'https://www.rc.radioking.com/widgets/api/v1',
    WIDGET_API_V2: 'https://api.rc.radioking.io/widget',
    WHMCS: 'https://rc.radioking.com/on',
    RADIOKING_DOMAIN: 'rc.radioking.com',
    RADIOKING_PLAY_DOMAIN: 'play.rc.radioking.com',
    WIDGETS: 'https://www.rc.radioking.com/widgets',
    WIDGETS_V2: 'https://widget.rc.radioking.io',
    PLAYER_WIDGET: 'https://player.rc.radioking.io',
    LISTEN: 'https://play.rc.radioking.io/',
    SMARTLINK: 'https://link.rc.radioking.com/',
    RADIO_PAGE: 'https://pageradio.rc.radioking.com/',
    WELOVE_DOMAIN: 'rc.welove.radio',
  },
  whmcsId: {
    Demo: 44,
    Discover: 57,
    Start: 45,
    Pro: 46,
    Business: 47,
  },
  auth: {
    isOnManagerLoginEnable: false,
    redirectUrl:
      'https://www.rc.radioking.com/login?redirect=https:%2F%2Fmanager.rc.radioking.com',
  },
  sentry: {
    url: 'https://5755836ebc9947999085e76c383e406f@sentry.io/1487411',
    isEnabled: true,
    env: 'rc',
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR', 'en-GB', 'de-DE'],
  cookies: {
    config: {
      domain: '.rc.radioking.com',
      expires: 365,
      secure: true,
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
  googleMapsApiKey: 'AIzaSyB5Si3nNVu8PQAHhAyYOIr0351CUitTkws',
  amplitudeApiKey: 'f1f1db4a59862a02677e5e812857fc24',
  facebookPixelId: '',
  gtm: 'GTM-PFH3HDC',
};
