import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Billing, RADIO_PLAN_DESC } from '@app/core/models/Radio';
import { whmcsCurrency } from '@app/core/models/radio-api.model';
import { AuthState } from '@app/core/states/auth.state';
import { LogButtonToggle, LogModal } from '@app/core/states/events-tracking.actions';
import { EventProperties } from '@app/core/states/events-tracking.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { planBigger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

export const PRICES = {
  Monthly: {
    EUR: {
      Discover: '14€',
      Start: '29€',
      Pro: '54€',
      Business: '99€',
    },
    GBP: {
      Discover: '£14',
      Start: '£29',
      Pro: '£54',
      Business: '£99',
    },
    USD: {
      Discover: '$19',
      Start: '$34',
      Pro: '$64',
      Business: '$119',
    },
  },
  Annually: {
    EUR: {
      Discover: '14O€',
      Start: '288€',
      Pro: '528€',
      Business: '1008€',
    },
    GBP: {
      Discover: '£140',
      Start: '£288',
      Pro: '£528',
      Business: '£1008',
    },
    USD: {
      Discover: '$190',
      Start: '$340',
      Pro: '$640',
      Business: '$1190',
    },
  },
  NoDiscount: {
    EUR: {
      Discover: '168€',
      Start: '348€',
      Pro: '648€',
      Business: '1188€',
    },
    GBP: {
      Discover: '£168',
      Start: '£348',
      Pro: '£648',
      Business: '£1188',
    },
    USD: {
      Discover: '$228',
      Start: '$408',
      Pro: '$768',
      Business: '$1428',
    },
  },
  RadioPageOption: {
    EUR: '5€',
    GBP: '£5',
    USD: '$5',
  },
};

@Component({
  selector: 'rk-upgrade-offer-modal',
  templateUrl: './upgrade-offer-modal.component.html',
  styleUrls: ['./upgrade-offer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeOfferModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(RadioState.currency)
  currency$: Observable<whmcsCurrency>;

  billing: Billing = 'Monthly';

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  @Select(LiveTrackingState.planDesc)
  planDesc$: Observable<RADIO_PLAN_DESC>;

  recommendedPlan = 'Start';
  $listeningLocale = signal('en-US');
  subscription = new Subscription();

  swiped: Subject<void> = new Subject<void>();

  // Config for Demo/Discover
  swiperConfig: SwiperOptions = {
    navigation: false,
    spaceBetween: 20,
    touchMoveStopPropagation: true,
    breakpoints: {
      320: {
        slidesPerView: 1.05,
        initialSlide: 1,
        enabled: true,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.05,
        initialSlide: 1,
        centeredSlides: false,
      },
      1024: {
        slidesPerView: 3.3,
      },
      1200: {
        slidesPerView: 4,
        enabled: false,
      },
    },
  };

  plansId = environment.whmcsId;

  constructor(
    public readonly dialogRef: MatDialogRef<UpgradeOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public originName: string,
  ) {}

  ngOnInit() {
    this.logModal({
      name: AMPLITUDE_EVENT.MODAL.UPGRADE_OFFER,
      origin: this.originName,
    });
    this.planDesc$.pipe(filter(p => !!p)).subscribe(p => {
      switch (p) {
        case 'Start':
          this.recommendedPlan = 'Pro';
          this.swiperConfig = {
            slidesPerView: 3,
            initialSlide: 1,
            navigation: false,
            centeredSlides: true,
            spaceBetween: 20,
            touchMoveStopPropagation: true,
            breakpoints: {
              320: {
                slidesPerView: 1.1,
                centeredSlides: true,
                enabled: true,
              },
              768: {
                slidesPerView: 2.05,
                initialSlide: 1,
                centeredSlides: false,
                enabled: true,
              },
              1024: {
                slidesPerView: 3,
                enabled: false,
              },
            },
          };
          break;
        case 'Pro':
          this.recommendedPlan = 'Business';
          this.swiperConfig = {
            initialSlide: 1,
            navigation: false,
            spaceBetween: 20,
            touchMoveStopPropagation: true,
            breakpoints: {
              320: {
                slidesPerView: 1.1,
                centeredSlides: true,
                enabled: true,
              },
              768: {
                slidesPerView: 2,
                enabled: false,
              },
            },
          };
      }
    });
    this.userLang$.pipe(first()).subscribe(lng => {
      if (lng === 'fr') {
        this.$listeningLocale.set('fr-FR');
      }
    });
  }

  ngAfterViewInit() {
    // Homogenize features height
    setTimeout(() => {
      let idx = 1;
      while (idx < 10) {
        const divGroup = document.querySelectorAll(`.vl-${idx++}`);
        let maxHeight = 0;
        divGroup.forEach(d => {
          maxHeight = Math.max(maxHeight, (d as HTMLElement).clientHeight);
        });
        divGroup.forEach(d => {
          (d as HTMLElement).style.height = `${maxHeight}px`;
        });
      }
    }, 70);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  swipeOffer = () => this.swiped.next();

  onCancel(log = false) {
    if (log) {
      aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.CLOSE);
    }
    this.dialogRef.close(false);
  }

  changeBilling(e: MatButtonToggleChange) {
    this.logButtonToggle({
      name: `ButtonToggle${e.value}`,
      origin: AMPLITUDE_EVENT.MODAL.UPGRADE_OFFER,
    });
    this.billing = e.value;
  }

  @Dispatch()
  logModal = (properties: EventProperties) => new LogModal(properties);

  @Dispatch()
  logButtonToggle = (properties: EventProperties) => new LogButtonToggle(properties);

  protected readonly planBigger = planBigger;
}
