import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/services/i18n.service';
import { MyRadiosRequest, UseSpecificRadio } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { getDeviceCategory } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GtmService } from '@radioking/shared/common-services';
import { Logger } from '@radioking/shared/logger';
import * as Sentry from '@sentry/browser';
import amplitude from 'amplitude-js';
import moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BrevoService } from '../../../../../../libs/shared/common-services/src/lib/services/brevo.service';

import {
  AuthenticationService,
  TokenItem,
  User,
} from '../authentication/authentication.service';

import {
  LoginError,
  LoginRequest,
  Logout,
  RedirectToLogin,
  RequestUser,
  SetTokens,
  SetUser,
} from './auth.actions';

export interface AuthStateModel {
  isLoggingIn: boolean;
  user: {
    username: string;
    firstname: string;
    email: string;
    id: number;
    imageUrl?: string;
    lastRadioUsed: number;
    lang: string;
    isAdmin: boolean;
    isSuperUser: boolean;
    whmcsId: number;
    onlySocial: boolean;
    numberLang?: string;
    lateInvoice?: {
      id: number;
      dueDate: string;
    };
  };
  auth: {
    token: string;
    expire: number;
    refreshToken: string;
  };
}

const log = new Logger('auth store');

const defaultUser = {
  username: '',
  firstname: '',
  email: '',
  id: -1,
  lastRadioUsed: -1,
  lang: '',
  isSuperUser: false,
  isAdmin: false,
  whmcsId: -1,
  onlySocial: false,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    auth: {
      token: '',
      refreshToken: '',
      expire: -1,
    },
    user: defaultUser,
    isLoggingIn: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
    private readonly i18nService: I18nService,
    private readonly gtmService: GtmService,
    private readonly brevoService: BrevoService,
  ) {}

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.auth.token;
  }

  @Selector()
  static isLogging(state: AuthStateModel): boolean {
    return state.isLoggingIn;
  }

  @Selector()
  static currentUsername(state: AuthStateModel): string {
    return state.user.username;
  }

  @Selector()
  static currentFirstname(state: AuthStateModel): string {
    return state.user.firstname;
  }

  @Selector()
  static currentEmail(state: AuthStateModel): string {
    return state.user.email;
  }

  @Selector()
  static currentProfilePic(state: AuthStateModel): string {
    return state.user.imageUrl;
  }

  @Selector()
  static getAccessToken(state: AuthStateModel): string {
    return state.auth.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): string {
    return state.auth.refreshToken;
  }

  @Selector()
  static isSuperUser(state: AuthStateModel): boolean {
    return state.user.isSuperUser;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.user.isAdmin;
  }

  @Selector()
  static isAdminOrSuperuser(state: AuthStateModel): boolean {
    return state.user.isAdmin || state.user.isSuperUser;
  }

  @Selector()
  static userId(state: AuthStateModel): number {
    return state.user.id;
  }

  @Selector()
  static userWHMCSId(state: AuthStateModel): number {
    return state.user.whmcsId;
  }

  @Selector()
  static userOnlySocial(state: AuthStateModel): boolean {
    return state.user.onlySocial;
  }

  @Selector()
  static userLang(state: AuthStateModel): string {
    return state.user.lang;
  }

  @Selector()
  static welove(state: AuthStateModel): string {
    const domain = state.user.lang === 'fr' ? 'fr.' : '';

    return `https://${domain}${environment.urls.WELOVE_DOMAIN}`;
  }

  @Action(RequestUser)
  requestUser(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ isLoggingIn: true });

    return this.authenticationService.getMe().pipe(
      map((user: User) => {
        ctx.dispatch(new SetUser(user, false));

        return user;
      }),
      switchMap(() => this.getUserRadio(ctx)),
      catchError(() => ctx.dispatch(new Logout())),
    );
  }

  @Action(SetUser)
  setUsername(ctx: StateContext<AuthStateModel>, { user, isRedirection }: SetUser) {
    ctx.patchState({
      user: {
        username: user.username,
        firstname: user.firstname,
        email: user.email,
        id: user.iduser,
        imageUrl: user.idfile_avatar,
        lastRadioUsed: user.lastRadioUsed,
        lang: user.lang,
        isSuperUser: user.is_superuser,
        isAdmin: user.is_admin,
        whmcsId: user.idwhmcs,
        onlySocial: user.only_social,
      },
      isLoggingIn: false,
    });

    let name = user.username;

    if (user.firstname && user.lastname) {
      name = `${user.firstname} ${user.lastname}`;
    }

    if (environment.gtm) {
      const dataLayer = {
        id_user: user.iduser,
        email_user: user.email,
        name_user: user.lastname,
        first_name: user.firstname,
        country_user: user.country,
        user_zendesk_hash: user.zendesk_hash,
      };
      if (user.lateInvoice) {
        const dueDays = moment().diff(moment(user.lateInvoice.dueDate), 'days');
        dataLayer['lateInvoiceID'] = user.lateInvoice.id;
        dataLayer['lateInvoiceDueDate'] = user.lateInvoice.dueDate;
        dataLayer['lateInvoiceDays'] = dueDays;
      }
      if (user.organization) {
        dataLayer['typologie'] = user.organization;
      }
      this.gtmService.pushToDataLayer(dataLayer);
    }

    amplitude.getInstance().init(environment.amplitudeApiKey, user.iduser.toString());
    const userProperties = {
      name,
      email: user.email,
      whmcsId: user.idwhmcs,
      lang: user.lang,
      device_size: `${window.screen.width.toString()} x ${window.screen.height.toString()}`,
      device_category: getDeviceCategory(),
      product_name: 'Radio Manager',
    };
    amplitude.getInstance().setUserProperties(userProperties);
    this.brevoService.setUserProperties(userProperties);

    this.i18nService.language = user.lang;
    if (isRedirection) {
      ctx.dispatch(new Navigate(['radio', 0], {}, { replaceUrl: true }));
    }

    if (environment.sentry.isEnabled) {
      Sentry.setUser({
        id: `${user.iduser}`,
        email: user.email,
        username: user.username,
      });
    }
  }

  @Action(SetTokens)
  setTokens(
    ctx: StateContext<AuthStateModel>,
    { token, expire, refreshToken }: SetTokens,
  ) {
    ctx.patchState({ auth: { token, expire, refreshToken } });
  }

  @Action(LoginError)
  loginError(ctx: StateContext<AuthStateModel>, { error }: LoginError) {
    ctx.patchState({ isLoggingIn: false });
    log.warn('An error was found during loggin : ', error);
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    return this.authenticationService.logout().pipe(
      catchError(() => of()),
      mergeMap(() => ctx.dispatch(new RedirectToLogin())),
    );
  }

  @Action(RedirectToLogin)
  redirectToLogin(ctx: StateContext<AuthStateModel>): Observable<void> | void {
    ctx.patchState({
      user: defaultUser,
      auth: {
        refreshToken: '',
        expire: 0,
        token: '',
      },
      isLoggingIn: false,
    });
    if (environment.auth.isOnManagerLoginEnable) {
      return ctx.dispatch(new Navigate(['/login'], {}, { replaceUrl: true }));
    }
    document.location.href = environment.auth.redirectUrl;
  }

  @Action(LoginRequest)
  loginRequest(ctx: StateContext<AuthStateModel>, { login, password }: LoginRequest) {
    ctx.patchState({ isLoggingIn: true });

    return this.authenticationService.login({ password, username: login }).pipe(
      map((tok: TokenItem) =>
        ctx.dispatch(new SetTokens(tok.access_token, tok.expire, tok.refresh_token)),
      ),
      switchMap(() => this.authenticationService.getMe()),
      switchMap((user: User) => ctx.dispatch(new SetUser(user, true))),
      switchMap(() => this.getUserRadio(ctx)),
      catchError(error => ctx.dispatch(new LoginError(error))),
    );
  }

  @Action(UseSpecificRadio)
  setCurrentRadio(ctx: StateContext<AuthStateModel>, { idRadio }: UseSpecificRadio) {
    return this.authenticationService.useSpecificRadio(ctx.getState().user.id, idRadio);
  }

  getUserRadio(ctx: StateContext<AuthStateModel>): Observable<any> {
    const userId = ctx.getState().user.id;

    return of('').pipe(
      switchMap(() => ctx.dispatch(new MyRadiosRequest(userId))),
      switchMap(() => {
        const radios = this.store.selectSnapshot(RadioState.radios);
        if (radios.length === 0) {
          return ctx.dispatch(new Navigate(['/no-radio'], {}, { replaceUrl: true }));
        }
        let lastRadioId = ctx.getState().user.lastRadioUsed;
        const routeRadio = window.location.pathname.split('/');
        const radioIndex = routeRadio.indexOf('radio');
        const idRadioUrl = parseInt(routeRadio[radioIndex + 1], 10);
        if (radioIndex > -1 && routeRadio.length > radioIndex + 1 && idRadioUrl !== 0) {
          lastRadioId = idRadioUrl;
        }
        const foundRadio = radios.find(radio => radio.id === lastRadioId);
        if (foundRadio) {
          return ctx.dispatch(new UseSpecificRadio(foundRadio.id));
        } else {
          if (
            (ctx.getState().user.isSuperUser || ctx.getState().user.isAdmin) &&
            lastRadioId !== 0
          ) {
            return ctx.dispatch(new UseSpecificRadio(lastRadioId));
          }
        }

        return ctx.dispatch(new UseSpecificRadio(radios[0].id));
      }),
    );
  }
}
